import colors from './colors'
import { themes } from './constants'

export default {
    id: `T_001`,
    name: themes.DARK,
    styles: {
        background: colors.black,
    },
}
