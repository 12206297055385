export const _playing = (state) => state?.playerReducer?.playing
export const _autoPlayed = (state) => state?.playerReducer?.autoPlayed
export const _soundValue = (state) => state?.playerReducer?.soundValue
export const _playlist = (state) => state?.playerReducer?.playlist
export const _currentTrackId = (state) => state?.playerReducer?.currentTrackId
export const _showPlayer = (state) => state?.playerReducer?.showPlayer
export const _showDownloadPrompt = (state) =>
    state?.playerReducer?.showDownloadPrompt
export const _showSubscriptionPrompt = (state) =>
    state?.playerReducer?.showSubscriptionPrompt
export const _selectedEpisodePrice = (state) =>
    state?.playerReducer?.selectedEpisodePrice
export const _onlyEpisode = (state) => state?.playerReducer?.onlyEpisode
export const _currentSubscriptionContentId = (state) =>
    state?.playerReducer?.currentSubscriptionContentId
export const _currentSubscriptionContentDetails = (state) =>
    state?.playerReducer?.currentSubscriptionContentDetails
export const _showDownloadPromptText = (state) =>
    state?.playerReducer?.promptText
export const _toggleScreen = (state) => state?.playerReducer?.toggleScreen
export const _listenerCount = (state) => state?.playerReducer?.listenerCount
export const _trackCount = (state) => state?.playerReducer?.trackCount
export const _playlistDetails = (state) => state?.playerReducer?.playlistDetails
export const _currentPlayingCatalog = (state) =>
    state?.playerReducer?.currentPlayingCatalog
export const _joinedLiveroom = (state) => state?.playerReducer?.joinedLiveroom
export const _currentLiveroomParticipantCount = (state) =>
    state?.playerReducer?.currentLiveroomParticipantCount
export const _currentLiveSpeakers = (state) =>
    state?.playerReducer?.currentLiveSpeakers
export const _currentLiveroom = (state) => state?.playerReducer?.currentLiveroom
export const _playingRadioListenerCount = (state) =>
    state?.playerReducer?.playingRadioListenerCount
export const _showLoginPrompt = (state) => state?.playerReducer?.showLoginPrompt
export const _currentContentId = (state) =>
    state?.playerReducer?.currentContentId
export const _contentElapsedTimeType = (state) =>
    state?.playerReducer?.contentElapsedTimeType
