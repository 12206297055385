import { db } from '@/utils/firebase'
import { doc, getDoc, increment, updateDoc } from 'firebase/firestore'

export const radioListenerCountHandler = async (
    count,
    radioId,
    isReload = false
) => {
    const firebaseAuth =
        typeof window !== 'undefined' && localStorage.getItem('firebase-auth')
    if (firebaseAuth && count && radioId) {
        const radioListenerCountRef = doc(db, 'Content', radioId)
        if (isReload) {
            updateDoc(radioListenerCountRef, {
                listeners: increment(count),
            })
            return
        }

        const docSnap = await getDoc(radioListenerCountRef)

        if (docSnap.exists()) {
            await updateDoc(radioListenerCountRef, {
                listeners: increment(count),
            })
        } else {
            // console.log('No such document!')
        }
    }
}
