export const _userDetails = (state) => state?.userReducer?.userDetails
export const _userCatalogs = (state) => state?.userReducer?.userCatalogs
export const _userActivities = (state) => state?.userReducer?.userActivities
export const _fetchUserProfileStatus = (state) =>
    state?.userReducer?.fetchUserProfileStatus

export const _totalUserCatalogCount = (state) =>
    state?.userReducer?.totalUserCatalogCount

export const _totalUserActivitiesCount = (state) =>
    state?.userReducer?.totalUserActivitiesCount

export const _activeProfileTab = (state) => state?.userReducer?.activeProfileTab

export const _profileDetails = (state) => state?.userReducer?.profileDetails

export const _followers = (state) => state?.userReducer?.followers

export const _totalFollowersCount = (state) =>
    state?.userReducer?.totalFollowersCount
export const _following = (state) => state?.userReducer?.following

export const _totalFollowingCount = (state) =>
    state?.userReducer?.totalFollowingCount

export const _fetchFollowersStatus = (state) =>
    state?.userReducer?.fetchFollowersStatus
export const _fetchFollowingStatus = (state) =>
    state?.userReducer?.fetchFollowingStatus

export const _showRegistrationPrompt = (state) =>
    state?.userReducer?.showRegistrationPrompt

export const _getUserWallet = (state) => state?.userReducer?.wallet

export const _getUserWalletTransaction = (state) =>
    state?.userReducer?.walletTransactions

export const _getUserSubscription = (state) =>
    state?.userReducer?.userSubscription
