import { AmplitudeEventTracking } from '@/helpers/amplitudeEventTracking'
import { radioListenerCountHandler } from '@/helpers/radioListenerHandler'
import { setShowLoginPrompt } from '@/store/actions/player'
import * as playerSelectors from '@/store/selectors/player'
import * as userSelectors from '@/store/selectors/user'
import { Container } from '@/styles/styles'
import { GlobalStyles } from '@/themes/GlobalStyles'
import dark from '@/themes/dark'
import { validateFirebaseAccess } from '@/utils/api-ssr'
import { analytics } from '@/utils/firebase'
import { setUserId, setUserProperties } from 'firebase/analytics'
import { getAuth, signInWithCustomToken } from 'firebase/auth'
import { useSession } from 'next-auth/react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import { useDispatch, useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'

// Lazy load components
const AudioPlayer = dynamic(() => import('@/components/AudioPlayer'))
const DownloadModal = dynamic(() => import('@/components/DownloadModal'))
const Footer = dynamic(() => import('@/components/Footer'))
const Sidebar = dynamic(() => import('./sidebar'))
const LoginModal = dynamic(() => import('../LoginModal'))
const RegisterModal = dynamic(() => import('../RegisterModal'))

const BaseLayout = (props) => {
    const [utmSource, setUtmSource] = useState('noice_web')
    const [utmMedium, setUtmMedium] = useState('')
    const [utmCampaign, setUtmCampaign] = useState('')

    const showRegistrationPrompt = useSelector(
        userSelectors._showRegistrationPrompt
    )
    const playing = useSelector(playerSelectors._playing)
    const showPlayer = useSelector(playerSelectors._showPlayer)
    const currentPlayingCatalog = useSelector(
        playerSelectors._currentPlayingCatalog
    )

    const { data: session } = useSession()
    const router = useRouter()

    const isUserRegistration = router.pathname === '/user-registration'
    const isMission = router.pathname.includes('/missions')
    const isCarLogin = router.pathname.startsWith('/car-login')
    const isAppUser = session?.type === 'app' || session?.type === 'appGuest'

    useEffect(() => {
        if (typeof window !== 'undefined') {
            // Track Traffic Source
            const searchParams = new URLSearchParams(window.location.search)
            setUtmSource(searchParams.get('utm_source'))
            setUtmMedium(searchParams.get('utm_medium'))
            setUtmCampaign(searchParams.get('utm_campaign'))
        }
    }, [])

    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (session && session?.isLoggedin) {
                setUserId(analytics, session?.userInfo?.id)
                setUserProperties(analytics, {
                    user_id:
                        session?.userInfo?.id || localStorage.getItem('userId'),
                })
            } else {
                setUserProperties(analytics, {
                    guest_user_id:
                        session?.guestUserId || localStorage.getItem('userId'),
                })
            }

            const trafficEventName = 'traffic source'
            const trafficEventParams = {}
            trafficEventParams.source = utmSource || 'web'
            trafficEventParams.medium = utmMedium || 'web'
            trafficEventParams.campaign = utmCampaign || 'web'

            if (session?.token) {
                AmplitudeEventTracking(
                    trafficEventName,
                    trafficEventParams,
                    session
                )
            }
        }
    }, [session, utmCampaign, utmMedium, utmSource])

    useEffect(() => {
        if (!session) return
        const validateFirebase = async () => {
            const auth = getAuth()

            const { firebaseNoiceToken } = await validateFirebaseAccess(
                session?.token
            )

            if (firebaseNoiceToken)
                signInWithCustomToken(auth, firebaseNoiceToken)
        }

        validateFirebase()
    }, [session])

    useEffect(() => {
        const listnerCountHandler = () => {
            if (playing && showPlayer && currentPlayingCatalog?.type === 'radio')
                radioListenerCountHandler(-1, currentPlayingCatalog.id, true)
        }

        window.addEventListener('beforeunload', listnerCountHandler)
        return () => {
            window.removeEventListener('beforeunload', listnerCountHandler)
        }
    }, [currentPlayingCatalog, playing, showPlayer])

    const dispatch = useDispatch()
    const isLoggedin = session?.isLoggedin

    useEffect(() => {
        if (isLoggedin) {
            dispatch(setShowLoginPrompt(false))
        }
    }, [isLoggedin, dispatch])

    return (
        <ThemeProvider theme={dark}>
            <GlobalStyles />
            <Container className="parent-container">
                {!isCarLogin && <Sidebar />}

                <div className="content">
                    {props.children}
                    <div style={{ marginBottom: '130px' }} />
                </div>

                <AudioPlayer />
                {!isUserRegistration && <DownloadModal />}
                {!isAppUser &&
                    !isUserRegistration &&
                    !isMission &&
                    !isCarLogin && <Footer />}
                <LoginModal />
                {showRegistrationPrompt && <RegisterModal />}
            </Container>
        </ThemeProvider>
    )
}

export default BaseLayout
