import { createGlobalStyle } from 'styled-components'

const styled = { createGlobalStyle }

export const GlobalStyles = styled.createGlobalStyle`
    * {
        box-sizing: border-box;
        -webkit-tap-highlight-color: transparent;
    }

    @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) {
            * {
                letter-spacing: 0 !important;
            }
        }
    }

    @font-face {
        font-family: Readex Pro;
        src: url('/font/ReadexPro-Regular.ttf') format('truetype');
        font-display: swap;
    }

    @font-face {
        font-family: Readex Pro;
        src: url('/font/ReadexPro-Regular.ttf') format('truetype');
        font-display: swap;
    }

    body {
        margin: 0;
        padding: 0;
        font-family: 'Readex Pro', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background: ${(props) => props.theme.styles.background};
    }

    img {
        max-width: 100%;
        height: auto;
    }

    a {
        text-decoration: none;
    }

    .slick-dots li {
        width: auto;
        margin: 0 2px;
    }

    .slick-dots li button {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        border: 0 none;
        background-color: #fafafa;
        opacity: 0.3;
        padding: 0px;
    }

    .slick-dots li.slick-active button {
        opacity: 1;
    }

    .slick-dots li button::before {
        content: '';
    }

    @media (min-width: 992px) {
        #root {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .readMoreText {
        color: #fad810 !important;
    }

    :root {
        --rsbs-backdrop-bg: rgba(0, 0, 0, 0.6);
        --rsbs-bg: black;
        --rsbs-max-w: auto;
        --rsbs-ml: env(safe-area-inset-left);
        --rsbs-mr: env(safe-area-inset-right);
        --rsbs-overlay-rounded: 16px;
        --rsbs-max-w: 100%;
        --rsbs-handle-bg: #333333;
    }

    @media (min-width: 992px) {
        #__next {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        :root {
            --rsbs-max-w: 698px;
            --rsbs-ml: auto;
            --rsbs-mr: auto;
        }
    }

    .parentLiveSkeletonClass {
        width: 100% !important;
    }
    .parentSkeletonClass {
        width: 100%;
        height: 100%;
        line-height: 1;
    }

    .rm-overflow-hidden {
        margin: unset;
    }
    .rm-more-button {
        color: #fad810;
        background: none;
        border: none;
    }
    .rm-less-button {
        color: #fad810;
        background: none;
        border: none;
    }

    .benefit-desc a {
        color: var(--vb-ref-color-primary-50, #fad810);
    }

    .ant-radio-inner {
        border-color: #ffffff !important;
        background-color: unset !important;

        ::after {
            background-color: #fad810 !important;
        }
    }

    .topUpCoinTooltip .ant-tooltip-arrow {
        top: 1px !important;
    }
`
